.headerContainer {
  width: 100%;
  height: 55px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-inline: 0 !important;
  background: #fff !important;
  font-size: 13px;
  border-bottom: 1px solid #e5e5e5;
  z-index: 10;

  > div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    margin: 0 30px;
  }
}

.headerContent {
  width: 100%;
  justify-content: space-between;
  margin-left: 0 !important;
  margin-right: 30px !important;
}

.userInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  > span {
    margin-left: 10px;
  }
}

.licenceInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #262626;

  > p {
    margin: 0;
    line-height: normal;
    font-size: 12px;
  }
}

.logoWrapper {
  margin-left: 20px !important;
  width: 190px;
  z-index: 9;
  font-size: 22px;
  height: 55px;

  > a {
    width: 130px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
