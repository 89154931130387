$bgNavColor: #1d2226;

html {
  font-size: 0.75rem;
  width: 100%;
}

// antd overriding styles
.ant-layout {
  background-color: #ffffff !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none !important;
}

.ant-menu {
  font-size: 12px !important;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

// main styles

.layout__content {
  height: 100%;
  margin-left: 10px;
}

.layout__nav-sider {
  border-right: 1px solid #e5e5e5;
  padding-top: 20px;
  background: $bgNavColor !important;

  ul {
    background: $bgNavColor !important;
  }
}

.page-wrapper {
  padding: 20px 15px;
}

.page-content {
  padding: 10px 24px;
}

.page-title-tooltip-text {
  color: #000c17 !important;
  overflow: auto;
  max-height: 300px;
  max-width: 800px;
  width: 100%;
}

.page-title-tooltip .ant-tooltip-inner {
  padding: 20px !important;
  border-radius: 5px !important;
  max-height: 500px;
  max-width: 800px;
  width: 100%;
}

.page-title-tooltip {
  max-width: 500px !important;
}

.global-loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description-split {
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    border-left: 1px solid #f7f7f7;
    margin: 0 20px;
    padding: 20px;
  }
}

.un-form__wrapper {
  margin: 30px 0;
}

.btn__success {
  border-color: #14a55f;
  color: #14a55f;
}

.main-logo {
  width: 140px;
}
